import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { mainAnimations } from '@main/shared/animations/main-animations';
import { map, Subscription, Subject } from 'rxjs';
import { OnboardActionTypes } from '@main/shared/store/actions/onboard.action';
import { StoreState } from '@main/shared/store/index';
import { OnboardState } from '@main/shared/store/reducers/onboard.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss'],
  animations: [mainAnimations],
})
export class InvoicePaymentComponent implements OnInit {

  public paymentPhotoFile: any = '';
  public thumbClass: any;

  public paymentMethods: any[] = [{ 
    paymentMethodId: "gcash",
    paymentMethodName: "G-CASH"
  }];
  public paymentMethodSub$: Subscription;
  public paymentForm: FormGroup;
  public loading:boolean = true;
  public localData: any = localStorage.getItem('user');
  public dealData$: any;
  private req: Subscription;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<InvoicePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private managerOnboard: Store<StoreState>
  ) { }

  ngOnInit(): void {
    this.localData = JSON.parse(this.localData);
    this.loading = false;
    this.paymentForm = this.fb.group({
      modeOfPayment: ['', Validators.required],
      // amount: [this.data ? this.data.total_amount : '', Validators.required],
      receipt : ['', Validators.required],
    });

    this.dealData$ = this.managerOnboard.pipe(select(state => state.onboard));
    this.req =  this.dealData$.subscribe((lead: any) => {
      this.loading = lead.pending;
      
      if(lead.success){
        this.snackBar.open("Successful uploading of receipt, please wait for admin approval", "" , {
          duration: 4000,
          panelClass:'success-snackbar'
        });

        this.managerOnboard.dispatch({
          type:OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_SUCCESS,
          payload: null
        });

        this.closeModal();
      }

      if(lead.error){
        this.snackBar.open("Something went wrong please try again later or contact your administrator", "", {
          duration: 4000,
          panelClass:'danger-snackbar'
        });
        this.closeModal();

        this.managerOnboard.dispatch({
          type:OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_FAIL,
          payload: null
        }); 
      }
    })
  }

  loadPaymentMethod() {
    // this.invoiceFacade.getPaymentMethods();
    // this.paymentMethodSub$ = this.invoiceFacade.paymentMethods$
    //   .pipe(
    //     map((paymentMethod: any) => {
    //       if (paymentMethod) {
    //         this.paymentMethods = paymentMethod;
    //       } 
    //     })
    //   ).subscribe(inv => {
    //   });
  }

  onUpload(event: any): void {
    this.paymentPhotoFile = event.file;
    this.paymentForm.controls['receipt'].setValue(event.file);
    this.thumbClass = 'avatar-image-2';
  }

  closeModal(){
    this.dialogRef.close()
  }

  submitPayment(){
    if(this.paymentForm.valid){
      let data = {
        ...this.paymentForm.value,
        userId: this.localData.userId
      }
      this.managerOnboard.dispatch({
        type: OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT,
        payload: data
      }); 
    }
  }

  afterSubmit(event: string) {
    if (event == 'paymentSuccess') {
      this.dialogRef.close(event)
    }
  }

  ngOnDestroy(): void {
    if(this.req) this.req.unsubscribe();
  }


}
