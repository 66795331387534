export const environment = {
  production: false,
  //server: 'http://localhost:4500',
  server: 'https://ssr-back.herokuapp.com',
  main_product_id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcxNTE3OTU2MzQzNDk=',
  basePath: 'https://api.thelifeguide.app/api',
  NgRxName: 'App devtools',
  NgRxMaxAge: 15,
  isDebug: false,
  calculatorUrl: 'https://firebasestorage.googleapis.com/v0/b/thelifeguide.appspot.com/o/Resource-assets%2FLifeGuide%20UM%20Virality%20Model%20(4).xls?alt=media&token=9a86b621-b2e3-4781-bdb7-6094d64de91e',
  pitchVideoUrl: 'https://firebasestorage.googleapis.com/v0/b/thelifeguide.appspot.com/o/Resource-assets%2FLifeGuide%20Pitch%20-%20Complete%20Version.mp4?alt=media&token=ae6c189d-67fa-416e-b245-d06dd3215320',
  secondVidUrl: 'https://firebasestorage.googleapis.com/v0/b/thelifeguide.appspot.com/o/Resource-assets%2FLifeGuide%20Pitch%20-%20Shorter%20Version.mp4?alt=media&token=bb020690-1da9-4019-ac01-1fa8e0ed789a',
  ipGeolocation: "cfd0fc469c5249e985c10b252aa65d1d",
  PAGE_ID:"103088719485860"
};
