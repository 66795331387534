<nav class="navbar navbar-expand-lg navbar-transparent py-4 fixed-top">
  <div class="container d-flex px-0 px-lg-5">
    <a class="navbar-brand me-3 mx-lg-5" routerLink="/">
      <img src="/assets/images/placeholder/thelifeguideproject.png" class="hvr-grow" style="width: 150px;">
    </a>

    <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#togglerButton"
    aria-controls="togglerButton" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="togglerButton">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item ms-5 me-4">
          <a class="nav-link hvr-underline-from-left"

          aria-current="page" routerLink="/">Home</a>
        </li>

        <ng-container *ngFor="let item of headerItems">
          <li class="nav-item me-4" *ngIf="item?.route !== '/'">
            <a class="nav-link" aria-current="page"
            *ngIf="!item?.sub_routes"
            [ngClass]="location.match(item?.route) ? 'nav-link-active' : 'hvr-underline-from-left'"
            style="cursor: pointer;"
            (click)="navigateToRoute(item?.route)">{{item?.title}} </a>
          </li>
        </ng-container>

        <!-- <ng-container *ngFor="let item of headerItems">
          <li class="nav-item me-4" *ngIf="item?.route !== '/'">
            <ng-container *ngIf="item?.sub_routes && loggedInCustomer">
              <div class="dropdown d-flex">
                <button class="btn btn-nav-gradient" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                  Profile <mat-icon class="mat-icon ms-1">expand_more</mat-icon>
                </button>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li style="position: relative;" class="pt-2">
                    <span class="dropdown-item d-flex">
                      <img src="/assets/images/face-1.jpg" class="img-avatar me-3">
                      <div>
                        <h5 class="title-user">Joe Jhon</h5>
                        <p class="title-position">User</p>
                      </div>
                    </span>
                  </li>

                  <li *ngFor="let sub_item of item?.sub_routes">
                    <a class="dropdown-item" [routerLink]="sub_item?.route"
                    [ngClass]="location.match(sub_item?.route) ? 'nav-drop-link-active' : 'nav-drop-link'">{{sub_item?.title}}</a>
                  </li>

                  <li class="pb-2">
                    <span class="dropdown-item nav-drop-link" (click)="customerLogout()">Log out</span>
                  </li>
                </ul>
              </div>
            </ng-container>

            <ng-container *ngIf="item?.sub_routes && !loggedInCustomer">
              <div class="dropdown d-lg-flex">
                <button class="btn btn-nav-gradient" type="button" routerLink="/admin/signin">
                  Free Account
                </button>
              </div>
            </ng-container>
          </li>
        </ng-container> -->
      </ul>
    </div>
  </div>
</nav>

<div class="main">
  <router-outlet></router-outlet>
</div>



<app-footer></app-footer>
