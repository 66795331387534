import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  Actions,
  ofType,
  Effect,
  createEffect
} from '@ngrx/effects';

// import enum action types
import { AdminActionTypes } from '../actions/admin.action';
import { AdminService } from '../../services/api/admin.service';

@Injectable()
export class AdminEffect {
  constructor(
    private adminService: AdminService,
    private actions$: Actions
  ) { }

  public getLifeguideList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(AdminActionTypes.GET_LIFEGUIDE_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.adminService.getLifeguideListData(data)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: AdminActionTypes.GET_LIFEGUIDE_LIST_SUCCESS,
                payload: result
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: AdminActionTypes.GET_LIFEGUIDE_LIST_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public approveLifeguide = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(AdminActionTypes.APPROVE_LIFEGUIDE_LIST),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.adminService.approveLifeguide(data?.payload)
        .pipe(
          // return payload
          map((result: any) => {
            return {
              type: AdminActionTypes.APPROVE_LIFEGUIDE_LIST_SUCCESS,
              payload: result
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: AdminActionTypes.APPROVE_LIFEGUIDE_LIST_FAIL,
              payload: error,
            }),
          ),
        );
    }),
  ),
)

public declineLifeguide = createEffect(() =>
this.actions$.pipe(
  // set type
  ofType(AdminActionTypes.DECLINE_LIFEGUIDE_LIST),
  // switch to a new observable and cancel previous subscription
  switchMap((data: any) => {
    return this.adminService.declineLifeguide(data?.payload)
      .pipe(
        // return payload
        map((result: any) => {
          return {
            type: AdminActionTypes.DECLINE_LIFEGUIDE_LIST_SUCCESS,
            payload: result
          };
        }),
        catchError((error: any) =>
          // error handler
          of({
            type: AdminActionTypes.DECLINE_LIFEGUIDE_LIST_FAIL,
            payload: error,
          }),
        ),
      );
  }),
),
)

}
