import { CourseAction, CourseActionTypes } from '../actions/course.action';

// Create new interface for reducer
export interface CourseState {
  course: any;
  courseList: any;
  lesson: any;
  lessonList: any;
  quiz: any;
  quizList: any;
  pending: any;
  error: any;
  success: any;
  contactList: any;
  profile: any;
  leadList: any;
}

// Set initial state of the data
export const COURSE_INITIAL_STATE: CourseState = {
  course: [],
  courseList: [],
  lesson: [],
  lessonList: [],
  quiz: [],
  quizList: [],
  pending: false,
  error: null,
  success: null,
  contactList: null,
  profile: null,
  leadList: null,
};

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const CourseReducer = (
  state: CourseState = COURSE_INITIAL_STATE,
  action: CourseAction
): CourseState => {
  switch (action.type) {
    case CourseActionTypes.GET_COURSE_LIST:
      return { ...state, pending: true };

    case CourseActionTypes.GET_COURSE_LIST_SUCCESS:
      return { ...state, courseList: action.payload, pending: false };

    case CourseActionTypes.GET_COURSE_LIST_FAIL:
      return { ...state, pending: false, error: action.payload };

    case CourseActionTypes.GET_COURSE_DETAIL:
      return { ...state, pending: true };

    case CourseActionTypes.GET_COURSE_DETAIL_SUCCESS:
      return { ...state, course: action.payload, pending: false };

    case CourseActionTypes.GET_COURSE_DETAIL_FAIL:
      return { ...state, pending: false, error: action.payload };

    case CourseActionTypes.SELECT_COURSE:
      return { ...state, course: action.payload };

    case CourseActionTypes.GET_LESSON_LIST:
      return { ...state, pending: true };

    case CourseActionTypes.GET_LESSON_LIST_SUCCESS:
      return { ...state, lessonList: action.payload, pending: false };

    case CourseActionTypes.GET_LESSON_LIST_FAIL:
      return { ...state, pending: false, error: action.payload };

    case CourseActionTypes.GET_QUIZ_LIST:
      return { ...state, pending: true };

    case CourseActionTypes.GET_QUIZ_LIST_SUCCESS:
      return { ...state, quizList: action.payload, pending: false };

    case CourseActionTypes.GET_QUIZ_LIST_FAIL:
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
};
