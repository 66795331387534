import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  Actions,
  ofType,
  Effect,
  createEffect
} from '@ngrx/effects';

// import enum action types
import { TeamMemberActionTypes } from '../actions/team-member.action';
import { TeamMemberService } from '../../services/api/team-member.service';

@Injectable()
export class TeamMemberEffect {
  constructor(
    private teamService: TeamMemberService,
    private actions$: Actions
  ) { }

  public getTeamMemberList = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(TeamMemberActionTypes.GET_TEAM_LIST),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.teamService.getTeamMemberList(data)
        .pipe(
          // return payload
          map((result: any) => {
            return {
              type: TeamMemberActionTypes.GET_TEAM_LIST_SUCCESS,
              payload: result
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: TeamMemberActionTypes.GET_TEAM_LIST_FAIL,
              payload: error,
            }),
          ),
        );
    }),
  ),
  )

  public inviteTeam = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(TeamMemberActionTypes.SAVE_TEAM),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.teamService.InviteMember(data?.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: TeamMemberActionTypes.SAVE_TEAM_SUCCESS,
                payload: result
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: TeamMemberActionTypes.SAVE_TEAM_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public getHierarchyList = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(TeamMemberActionTypes.GET_HIERARCHY_LIST),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.teamService.getHierarchyList(data)
        .pipe(
          // return payload
          map((result: any) => {
            return {
              type: TeamMemberActionTypes.GET_HIERARCHY_LIST_SUCCESS,
              payload: result
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: TeamMemberActionTypes.GET_HIERARCHY_LIST_FAIL,
              payload: error,
            }),
          ),
        );
    }),
  ),
  )
}
