import { NgModule, ModuleWithProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffect } from './effects/auth.effect';
import { SignUpEffect } from './effects/sign-up.effect';
import { ManagerEffect } from './effects/manager.effect';
import { EventEffect } from './effects/event.effect';
import { OnboardEffect } from './effects/onboard.effect';
import { TeamMemberEffect } from './effects/team-member.effect';
import { ChannelEffect } from './effects/channel.effect';
import { CourseEffect } from './effects/course.effect';

import { AuthReducer } from './reducers/auth.reducer';
import { SignUpReducer } from './reducers/sign-up.reducer';
import { ManagerReducer } from './reducers/manager.reducer';
import { EventReducer } from './reducers/event.reducer';
import { OnboardReducer } from './reducers/onboard.reducer';
import { TeamMemberReducer } from './reducers/team-member.reducer';
import { ChannelReducer } from './reducers/channel.reducer';
import { CourseReducer } from './reducers/course.reducer';
import { AdminReducer } from './reducers/admin.reducer';
import { AdminEffect } from './effects/admin.effect';
import { DashboardReducer } from './reducers/dashboard.reducer';
import { DashboardEffect } from './effects/dashboard.effect';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', AuthReducer),
    StoreModule.forFeature('signUp', SignUpReducer),
    StoreModule.forFeature('manager', ManagerReducer),
    StoreModule.forFeature('event', EventReducer),
    StoreModule.forFeature('onboard', OnboardReducer),
    StoreModule.forFeature('teamMember', TeamMemberReducer),
    StoreModule.forFeature('channel', ChannelReducer),
    StoreModule.forFeature('admin', AdminReducer),
    StoreModule.forFeature('course', CourseReducer),
    StoreModule.forFeature('dashboard', DashboardReducer),
    EffectsModule.forFeature([
      AuthEffect,
      SignUpEffect,
      ManagerEffect,
      EventEffect,
      OnboardEffect,
      TeamMemberEffect,
      ChannelEffect,
      CourseEffect,
      AdminEffect,
      DashboardEffect
    ]),
  ],
  providers: [
    // services/injectables
  ],
})
export class StorePublicModule {
  public static forRoot(): ModuleWithProviders<StorePublicModule> {
    return {
      ngModule: StorePublicModule,
      providers: [
        //services/injectables
      ],
    };
  }
}
