import { 
	ManagerAction,
	ManagerActionTypes
	
} from '../actions/manager.action';

// Create new interface for reducer
export interface ManagerState {
	manager: any;
    ManagerCredentials: any;
	pending: any;
	error: any;
	success: any;
}

// Set initial state of the data
export const MANAGER_INITIAL_STATE: ManagerState = {
	manager: {},
    ManagerCredentials: [],
	pending: false,
	error: null,
	success: null
}

export const ManagerReducer = (
	state: ManagerState = MANAGER_INITIAL_STATE,
	action: ManagerAction,
	): ManagerState => {
		switch (action.type){

			case ManagerActionTypes.GET_MANAGER_CREDENTIALS:
			return { ...state, pending: true };

			case ManagerActionTypes.GET_MANAGER_CREDENTIALS_SUCCESS:
			return { ...state, manager: action.payload, pending: false }

			case ManagerActionTypes.GET_MANAGER_CREDENTIALS_FAIL:
			return { ...state, pending: false, error: action.payload }

			default:
			return state;
		}
	}
	
