<div class="container-fluid" id="receiptData" #receiptData>
  <div class="card">
    <section *ngIf="!loading">
      <div class="card-body row">
        <div class="col-12 col-md-12 text-center">
          <p>{{ data.message }}</p>
          <div class="pt-3" style="border-top: 1px dashed #c9c9c9;"
            [@animate]="{ value: '*', params: { y: '-50px', delay: '300ms' } }"
          >
            <h5 class="main-title mb-0">Payment</h5>
            <small class="mb-1"
              >Note: Please upload your proof of payment here</small
            >
          </div>
        </div>
        <hr />
        <div class="col-12 col-md-12 mt-2">
          <div class="">
            <label class="label-main">Amount: {{ data.amount }}</label>
          </div>
          <div class="">
            <label class="label-main">Transaction Fee: {{ data.transaction_fee }}</label>
          </div>
          <div class="">
            <label class="label-main">Total: {{ data.total_amount }}</label>
          </div>
        </div>
        <hr />
        <form [formGroup]="paymentForm">
          <div class="form-group mb-4 text-start">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-4 text-start">
                  <label class="label-sub"
                    >Payment Method <span class="asterisk">*</span></label
                  >
                  <select
                    class="form-select"
                    formControlName="modeOfPayment"
                    name="modeOfPayment"
                  >
                    <option [value]="''" disabled>Select Payment Method</option>
                    <ng-container
                      *ngFor="let item of paymentMethods; let i = index"
                    >
                      <option [value]="item.paymentMethodId">
                        {{ item.paymentMethodName }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="mb-4">
                  <div class="form-group text-start">
                    <label class="label-sub"
                      >Profile Image<span class="asterisk">*</span></label
                    >
                  </div>

                  <div class="attachment-container mb-3">
                    <div>
                      <!-- <img [src]="avatar && avatar !== '' ? avatar: '/assets/images/placeholder/image-upload-placeholder.png'"
                        [ngClass]="avatar ?'avatar-image-2': 'avatar-image' "> -->
                      <!-- <img
                        [ngClass]="thumbClass"
                        [src]="
                          paymentPhotoFile
                            ? paymentPhotoFile
                            : '/assets/images/image-upload-placeholder.png'
                        "
                      /> -->
                      <div style="margin-top: -10px">
                        <app-file-upload
                          (upload)="onUpload($event)"
                          [isMultiple]="false"
                        ></app-file-upload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <div class="col-12 text-center pt-3 pb-3" style="border-top: solid 1px #c9c9c9" *ngIf="!loading">
      <div class="w-100">
        <button class="btn hvr-hang btn-cancel me-3" (click)="closeModal()">Cancel</button>
        <button class="btn hvr-hang btn-submit" type="submit" [disabled]="!paymentForm.valid" (click)="submitPayment()">Submit</button>
      </div>
    </div>
		<custom-profile-loader 
			[withSideBar]="false"
			[fromDetailTab]="true"
			[loading]="loading">
		</custom-profile-loader>
  </div>
</div>