<div class="container-fluid">
  <div class="card">
    <section>
      <div class="card-body row card-body row px-0 py-1 mx-0">
        <div class="col-12 col-md-12 loading-animation">
          <div class="mb-2 text-center">

            <img src="assets/images/book-loading.gif" class="w-75" />

            <h2 class="color-blue-dark mt-3 loading-text">LOA<span class="text-primary-red">DING</span></h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
