import { DashboardAction, DashboardActionTypes } from '../actions/dashboard.action';

// Create new interface for reducer
export interface DashboardState {
  dashboard: any;
  courseProgress: any;
  pending: any;
  pendingCourses: any;
  error: any;
  success: any;
}

// Set initial state of the data
export const DASHBOARD_INITIAL_STATE: DashboardState = {
  dashboard: {},
  courseProgress: [],
  pending: false,
  pendingCourses: false,
  error: null,
  success: null,
};

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const DashboardReducer = (
  state: DashboardState = DASHBOARD_INITIAL_STATE,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARD:
      return { ...state, pending: true };

    case DashboardActionTypes.GET_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.payload, pending: false };

    case DashboardActionTypes.GET_DASHBOARD_FAIL:
      return { ...state, pending: false, error: action.payload };

    case DashboardActionTypes.GET_DASHBOARD_COURSE:
      return { ...state, pendingCourses: true };

    case DashboardActionTypes.GET_DASHBOARD_COURSE_SUCCESS:
      return { ...state, courseProgress: action.payload, pendingCourses: false };

    case DashboardActionTypes.GET_DASHBOARD_COURSE_FAIL:
      return { ...state, pendingCourses: false, error: action.payload };


    default:
      return state;
  }
};
