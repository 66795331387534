import { ChannelAction, ChannelActionTypes } from '../actions/channel.action';

// Create new interface for reducer
export interface ChannelState {
  channel: any;
  channelList: any;
  pending: any;
  error: any;
  success: any;
  contactList: any;
  profile: any;
  leadList: any;
}

// Set initial state of the data
export const CHANNEL_INITIAL_STATE: ChannelState = {
  channel: [],
  channelList: [],
  pending: false,
  error: null,
  success: null,
  contactList: null,
  profile: null,
  leadList: null,
};

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const ChannelReducer = (
  state: ChannelState = CHANNEL_INITIAL_STATE,
  action: ChannelAction
): ChannelState => {
  switch (action.type) {
    case ChannelActionTypes.GET_CHANNEL_LIST_MANAGER:
      return { ...state, pending: true };

    case ChannelActionTypes.GET_CHANNEL_LIST_MANAGER_SUCCESS:
      return { ...state, channelList: action.payload, pending: false };

    case ChannelActionTypes.GET_CHANNEL_LIST_MANAGER_FAIL:
      return { ...state, pending: false, error: action.payload };

      case ChannelActionTypes.GET_CHANNEL_LIST_MEMBER:
        return { ...state, pending: true };
  
      case ChannelActionTypes.GET_CHANNEL_LIST_MEMBER_SUCCESS:
        return { ...state, channelList: action.payload, pending: false };
  
      case ChannelActionTypes.GET_CHANNEL_LIST_MEMBER_FAIL:
        return { ...state, pending: false, error: action.payload };

    case ChannelActionTypes.GET_CHANNEL_DETAIL:
      return { ...state, pending: true };

    case ChannelActionTypes.GET_CHANNEL_DETAIL_SUCCESS:
      return { ...state, channel: action.payload, pending: false };

    case ChannelActionTypes.GET_CHANNEL_DETAIL_FAIL:
      return { ...state, pending: false, error: action.payload };

    case ChannelActionTypes.SELECT_CHANNEL:
      return { ...state, channel: action.payload };

    default:
      return state;
  }
};
