import { 
	EventAction,
	EventActionTypes
	
} from '../actions/event.action';

// Create new interface for reducer
export interface EventState {
	event: any;
    eventList: any;
	eventShareLink: any;
	eventDetail: any;
	invite: any;
	eventShareLinkPending: boolean;
	eventInvitePending: boolean;
	pending: any;
	error: any;
	success: any;
	eventDeleteResult: any;
	eventGuest: any;
}

// Set initial state of the data
export const EVENT_INITIAL_STATE: EventState = {
	event: {},
    eventList: [],
	pending: false,
	eventShareLink: null,
	eventDetail: null,
	eventShareLinkPending: false,
	eventInvitePending: false,
	invite: null,
	error: null,
	success: null,
	eventDeleteResult: null,
	eventGuest: []
}

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const EventReducer = (
	state: EventState = EVENT_INITIAL_STATE,
	action: EventAction,
	): EventState => {
		switch (action.type){

			case EventActionTypes.GET_EVENT:
			return { ...state, pending: true };

			case EventActionTypes.GET_EVENT_SUCCESS:
			return { ...state, event: action.payload, pending: false }

			case EventActionTypes.GET_EVENT_FAIL:
			return { ...state, pending: false, error: action.payload }

            case EventActionTypes.GET_EVENT_LIST:
            return { ...state, pending: true };

            case EventActionTypes.GET_EVENT_LIST_SUCCESS:
            return { ...state, eventList: action.payload, pending: false }

            case EventActionTypes.GET_EVENT_LIST_FAIL:
            return { ...state, pending: false, error: action.payload }

			case EventActionTypes.SAVE_EVENT:
			return { ...state, pending: true };

			case EventActionTypes.SAVE_EVENT_SUCCESS:
			return { ...state, success: action.payload, pending: false }

			case EventActionTypes.SAVE_EVENT_FAIL:
			return { ...state, pending: false, error: action.payload }

			case EventActionTypes.EDIT_EVENT:
			return { ...state, pending: true };

			case EventActionTypes.EDIT_EVENT_SUCCESS:
			return { ...state, success: action.payload, pending: false }

			case EventActionTypes.EDIT_EVENT_FAIL:
			return { ...state, pending: false, error: action.payload }

			case EventActionTypes.DELETE_EVENT:
			return { ...state, pending: true };

			case EventActionTypes.DELETE_EVENT_SUCCESS:
			return { ...state, eventDeleteResult: action.payload, pending: false }

			case EventActionTypes.DELETE_EVENT_FAIL:
			return { ...state, pending: false, error: action.payload }

            case EventActionTypes.GET_EVENT_SHARE_LINK:
            return { ...state, eventShareLinkPending: true };

            case EventActionTypes.GET_EVENT_SHARE_LINK_SUCCESS:
            return { ...state, eventShareLink: action.payload, eventShareLinkPending: false }

            case EventActionTypes.GET_EVENT_SHARE_LINK_FAIL:
            return { ...state, eventShareLinkPending: false, error: action.payload }

			case EventActionTypes.INVITE_EVENT:
            return { ...state, eventInvitePending: true };

            case EventActionTypes.INVITE_EVENT_SUCCESS:
            return { ...state, eventInvitePending: false, invite: action.payload }

            case EventActionTypes.INVITE_EVENT_FAIL:
            return { ...state, eventInvitePending: false, error: action.payload }

			case EventActionTypes.GET_EVENT_DETAIL:
			return { ...state, pending: true };

			case EventActionTypes.GET_EVENT_DETAIL_SUCCESS:
			return { ...state, eventDetail: action.payload, pending: false }

			case EventActionTypes.GET_EVENT_DETAIL_FAIL:
			return { ...state, pending: false, error: action.payload }

			case EventActionTypes.GET_EVENT_GUEST:
			return { ...state, pending: true };

			case EventActionTypes.GET_EVENT_GUEST_SUCCESS:
			return { ...state, eventGuest: action.payload, pending: false }

			case EventActionTypes.GET_EVENT_GUEST_FAIL:
			return { ...state, pending: false, error: action.payload }

			default:
			return state;
		}
	}
	
