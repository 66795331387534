import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private server = environment.basePath;
  constructor(private http: HttpClient, private router: Router) { }

  getAuth(data: any): Observable<any> {
    let url = `${this.server}/auth/signin`;
    if (data && data.role == 5) {
      url = `${this.server}/auth/advocate`;
    }
    return this.http.post<any>(url, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  changePassword(data: any): Observable<any> {
    return this.http.post<any>(`${this.server}/profile/change-password`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post<any>(`${this.server}/forgot-password`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  logoutUser(data: any): Observable<any> {
    return this.http.post<any>(`${this.server}/auth/logout?uid=${data}`, data)
      .pipe(
        map((res: any) => {
          localStorage.clear();
          this.router.navigate(['/']);
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any, caught: any): any {
    throw error;
  }
}
