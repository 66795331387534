import { 
	TeamMemberAction,
	TeamMemberActionTypes
	
} from '../actions/team-member.action';

// Create new interface for reducer
export interface TeamMemberState {
	teamMember: any;
    teamMemberList: any;
	pending: any;
	error: any;
	success: any;
	invitedMembers: any,
	hierarchyLists: any
	orgPending: any,
	orgSuccess: any,
	orgError: any,
}

// Set initial state of the data
export const TEAM_INITIAL_STATE: TeamMemberState = {
	teamMember: {},
    teamMemberList: [],
	pending: false,
	error: null,
	success: null,
	invitedMembers: null,
	hierarchyLists: [],
	orgPending: false,
	orgSuccess: null,
	orgError: null
}

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const TeamMemberReducer = (
	state: TeamMemberState = TEAM_INITIAL_STATE,
	action: TeamMemberAction,
	): TeamMemberState => {
		switch (action.type){

			case TeamMemberActionTypes.GET_TEAM:
			return { ...state, pending: true };

			case TeamMemberActionTypes.GET_TEAM_SUCCESS:
			return { ...state, teamMember: action.payload, pending: false }

			case TeamMemberActionTypes.GET_TEAM_FAIL:
			return { ...state, pending: false, error: action.payload }

            case TeamMemberActionTypes.GET_TEAM_LIST:
            return { ...state, pending: true };

            case TeamMemberActionTypes.GET_TEAM_LIST_SUCCESS:
            return { ...state, teamMemberList: action.payload, pending: false }

            case TeamMemberActionTypes.GET_TEAM_LIST_FAIL:
            return { ...state, pending: false, error: action.payload }

			case TeamMemberActionTypes.SAVE_TEAM:
			return { ...state, pending: true };

			case TeamMemberActionTypes.SAVE_TEAM_SUCCESS:
			return { ...state, invitedMembers: action.payload, pending: false }

			case TeamMemberActionTypes.SAVE_TEAM_FAIL:
			return { ...state, pending: false, error: action.payload }

			case TeamMemberActionTypes.GET_HIERARCHY_LIST:
			return { ...state, orgPending: true };

			case TeamMemberActionTypes.GET_HIERARCHY_LIST_SUCCESS:
			return { ...state, hierarchyLists: action.payload, orgPending: false }

			case TeamMemberActionTypes.GET_HIERARCHY_LIST_FAIL:
			return { ...state, orgPending: false, orgError: action.payload }

			default:
			return state;
		}
	}
	
