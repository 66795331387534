import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}

  getEventListData(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/event/list?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  saveEvent(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/event/create`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  editEvent(data: any): Observable<any> { 
    return this.http.put(`${this.server}/event/update`, data)
    .pipe(
      map((res) => <any>res)
    );
  }

  deleteEvent(data: any): Observable<any> {
    return this.http.delete<any>(`${this.server}/event/remove?eventId=${data}`)
    .pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getEventLink(data: any): Observable<any> {
    return this.http.post(`${this.server}/event/share`, data).pipe(
      map((res) => <any[]>res),
      catchError(this.handleError)
    );
  }

  inviteEvent(data: any): Observable<any> {
    return this.http.post(`${this.server}/event/inviteguest`, data).pipe(
      map((res) => <any[]>res),
      catchError(this.handleError)
    );
  }

  getEventDetails(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/event/details?eventId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }
  
  getEventGuest(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/event/guestlist?eventId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  // error handler
  private handleError(error: any, caught: any): any {
    throw error;
  }
}
