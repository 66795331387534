import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}
  
  InviteMember(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/team/invitemember`, data).pipe(
      map((res: any) => <any>res.data),
      catchError(this.handleError)
    );
  }

  getTeamMemberList(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/team/member?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  // getMemberDetails(data: any): Observable<any> {
  //   return this.http.get<any>(`${this.server}/member/profile?userId=${data.payload}`).pipe(
  //     map((res) => <any[]>res.data),
  //     catchError(this.handleError)
  //   );
  // }

  getHierarchyList(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/team/${data.payload.role}?userId=${data.payload.userId}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }


  // error handler
  private handleError(error: any, caught: any): any {
    throw error;
  }
}
