import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}

  AddContact(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/onboard/addcontact`, data).pipe(
      map((res: any) => <any>res.data),
      catchError(this.handleError)
    );
  }

  AddMultipleContact(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/onboard/multiplecontact`, data).pipe(
      map((res: any) => <any>res.data),
      catchError(this.handleError)
    );
  }

  getContactList(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/onboard/contactlist?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  getContactDetails(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/member/profile?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  getDealList(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/onboard/dealstage?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  updateStage(data:any): Observable<any> {
    return this.http.put<any>(`${this.server}/onboard/updatestage`, data).pipe(
      map((res: any) => <any>res.data),
      catchError(this.handleError)
    );
  }

  uploadPayment(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/onboard/uploadreceipt`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  // error handler
  private handleError(error: any, caught: any): any {
    throw error;
  }
}
