import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}

  getChannelDetail(data: any): Observable<any> {
    return this.http
      .get<any>(`${this.server}/channel/details?channelId=${data.payload.channelId}&userId=${data.payload.userId}`)
      .pipe(
        map((res) => <any[]>res.data),
        catchError(this.handleError)
      );
  }

  getChannelListManager(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/channel/list?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  getChannelListMember(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/channel/guest?userId=${data.payload}`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  // error handler
  private handleError(error: any, caught: any): any {
    throw error;
  }
}
