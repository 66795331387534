import { 
	SignUpAction,
	SignUpActionTypes
	
} from '../actions/sign-up.action';

// Create new interface for reducer
export interface SignUpState {
	signUp: any;
	pending: any;
	categories: any;
	error: any;
	successMerchantDetails: any;
    successMerchantConfiguration: any;
}

export const SIGN_UP_INITIAL_STATE: SignUpState = {
	signUp: {},
	pending: false,
	categories: [],
	error: null,
	successMerchantDetails: null,
    successMerchantConfiguration: null
}


export const SignUpReducer = (
	state: SignUpState = SIGN_UP_INITIAL_STATE,
	action: SignUpAction,
	): SignUpState => {
		switch (action.type){

			case SignUpActionTypes.SAVE_USER_DETAILS:
			return { ...state, pending: true};

			case SignUpActionTypes.SAVE_USER_DETAILS_SUCCESS:
			return { ...state, successMerchantDetails: action.payload,  pending: false }

			case SignUpActionTypes.SAVE_USER_DETAILS_FAIL:
			return { ...state, pending: false, error: action.payload }

			case SignUpActionTypes.REGISTER_USER_DETAILS:
			return { ...state, pending: true};

			case SignUpActionTypes.REGISTER_USER_DETAILS_SUCCESS:
			return { ...state, successMerchantDetails: action.payload,  pending: false }

			case SignUpActionTypes.REGISTER_USER_DETAILS_FAIL:
			return { ...state, pending: false, error: action.payload }

			default:
			return state;
		}
	}
	
