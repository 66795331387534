import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  Actions,
  ofType,
  Effect,
  createEffect
} from '@ngrx/effects';

// import enum action types
import { ManagerActionTypes } from '../actions/manager.action';
import { ManagerService } from '../../services/api/manager.service';

@Injectable()
export class ManagerEffect {
  constructor(
    private managerService: ManagerService,
    private actions$: Actions
  ) {}

  public getManager = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(ManagerActionTypes.GET_MANAGER_CREDENTIALS),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.managerService.getManagerCredentials(data)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: ManagerActionTypes.GET_MANAGER_CREDENTIALS_SUCCESS,
                payload: result
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: ManagerActionTypes.GET_MANAGER_CREDENTIALS_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )
}
