import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}

  getCourseDetail(data: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.server}/course/details?courseId=${data.payload.courseId}&userId=${data.payload.userId}`
      )
      .pipe(
        map((res) => <any[]>res.data),
        catchError(this.handleError)
      );
  }

  getCourseList(data: any): Observable<any> {
    return this.http.get<any>(`${this.server}/course/list`).pipe(
      map((res) => <any[]>res.data),
      catchError(this.handleError)
    );
  }

  getLessonList(data: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.server}/lesson/list?courseId=${data.payload.courseId}&userId=${data.payload.userId}`
      )
      .pipe(
        map((res) => <any[]>res.data),
        catchError(this.handleError)
      );
  }

  getQuizList(data: any): Observable<any> {
    return this.http
      .get<any>(`${this.server}//quiz/list?courseId=${data.payload.courseId}`)
      .pipe(
        map((res) => <any[]>res.data),
        catchError(this.handleError)
      );
  }

  // error handler
  private handleError(error: any, caught: any): any {
    throw error;
  }
}
