import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  Actions,
  ofType,
  Effect,
  createEffect
} from '@ngrx/effects';

// import enum action types
import { EventActionTypes } from '../actions/event.action';
import { EventService } from '../../services/api/event.service';

@Injectable()
export class EventEffect {
  constructor(
    private eventService: EventService,
    private actions$: Actions
  ) { }

  public getEventList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.GET_EVENT_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.getEventListData(data)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.GET_EVENT_LIST_SUCCESS,
                payload: result
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.GET_EVENT_LIST_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public saveEvent = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.SAVE_EVENT),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.saveEvent(data?.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.SAVE_EVENT_SUCCESS,
                payload: result
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.SAVE_EVENT_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public editEvent = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.EDIT_EVENT),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.editEvent(data?.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.EDIT_EVENT_SUCCESS,
                payload: result.status
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.EDIT_EVENT_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public deleteEvent = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.DELETE_EVENT),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.deleteEvent(data?.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.DELETE_EVENT_SUCCESS,
                payload: result.status
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.DELETE_EVENT_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public getEventShareLink = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.GET_EVENT_SHARE_LINK),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.getEventLink(data.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.GET_EVENT_SHARE_LINK_SUCCESS,
                payload: result.data
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.GET_EVENT_SHARE_LINK_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public inviteEvent = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(EventActionTypes.INVITE_EVENT),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.eventService.inviteEvent(data.payload)
          .pipe(
            // return payload
            map((result: any) => {
              return {
                type: EventActionTypes.INVITE_EVENT_SUCCESS,
                payload: result.data
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: EventActionTypes.INVITE_EVENT_FAIL,
                payload: error,
              }),
            ),
          );
      }),
    ),
  )

  public getEventDetail = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(EventActionTypes.GET_EVENT_DETAIL),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.eventService.getEventDetails(data).pipe(
        // return payload
        map((result: any) => {
          console.log("event detail")
          console.log(result)
          return {
            type: EventActionTypes.GET_EVENT_DETAIL_SUCCESS,
            payload: result,
          };
        }),
        catchError((error: any) =>
          // error handler
          of({
            type: EventActionTypes.GET_EVENT_DETAIL_FAIL,
            payload: error,
          })
        )
      );
    })
  )
);

public getEventGuest = createEffect(() =>
this.actions$.pipe(
  // set type
  ofType(EventActionTypes.GET_EVENT_GUEST),
  // switch to a new observable and cancel previous subscription
  switchMap((data: any) => {
    return this.eventService.getEventGuest(data)
      .pipe(
        // return payload
        map((result: any) => {
          return {
            type: EventActionTypes.GET_EVENT_GUEST_SUCCESS,
            payload: result
          };
        }),
        catchError((error: any) =>
          // error handler
          of({
            type: EventActionTypes.GET_EVENT_GUEST_FAIL,
            payload: error,
          }),
        ),
      );
  }),
),
)

}
