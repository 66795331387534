


<section class="mt-1" style="overflow-x: auto !important; width: 100%;">
	<div class="mb-2">
		<div class="search-div me-1 mb-3"
		[ngClass]="[!showStatData ? 'mb-3': '', exportable ? 'float-start' : 'float-end']">
			<div class="input-group">
			  <img src="/assets/images/placeholder/icons/search.png">
			  
			  <input type="text" class="form-control" placeholder="Search" aria-label="Search" 
			  [(ngModel)]="searchBy" (ngModelChange)="searchDataSource()">
			</div>
		</div>

		<div class="float-start">
			<span class="flex-wrap  table-controls" *ngIf="statusFilter">
				<button class="px-0 dropdown dropdown-toggle text-capitalize" 
				style="height: 43px; padding-left: 20px !important;" 
				type="button" id="lengthFilter" data-bs-toggle="dropdown" aria-expanded="false">
				{{ statusName || 'Status' }}
					<mat-icon class="material-icons-expand"  style="margin-top: 5px;">expand_more</mat-icon>
				</button>

				<ul class="dropdown-menu" aria-labelledby="lengthFilter" 
				style="width: 150px !important;">
					<li *ngFor="let item of statusArray">
						<a class="dropdown-item text-capitalize" (click)="filterByStatus(item)">{{item}}</a>
					</li>
				</ul>
			</span>
		</div>
	</div>

	<div *ngIf="exportable" class="table-controls-container float-end">
		<!-- CONTROLLER ICONS -->
		<div class="table-controls mt-3">
			<!-- <button class="px-0 me-3 pe-0" *ngFor="let action of tableController; let i = index"
			[ngClass]="i === 0 && !showImport ? 'd-none' : '' "
			[disabled]="action?.class === 'delete' && selectedRows?.length === 0"
			(click)="action?.action_event(openDialog(action?.class, selectedRows[0]?.id))">
				<img [src]="action?.icon" [ngClass]="action?.class">
				<span>{{action?.name}}</span>
			</button>
 -->
			<span id="export-table">
				<button style="position: relative;" 
					type="button"
					data-bs-toggle="dropdown" 
					aria-expanded="false" 
					class="dropdown ">
					<img src="/assets/images/placeholder/icons/export.png" class="export">Export {{componentTitle}}
				</button>

				<ul class="dropdown-menu" 
				style="position: absolute;" 
				aria-labelledby="status-filter">
					<li (click)="exportAsXLSX('template')">Export Template</li>
					<li (click)="exportAsXLSX('all')">Export All</li>
					<li (click)="exportAsXLSX('selected')">Export Selected</li>
					<li (click)="exportAsXLSX()">Export View Table</li>
				</ul>
			</span>

			<span class="flex-wrap pt-1 pt-sm-2 pt-md-1">
				<button class="px-0 dropdown dropdown-toggle" 
				type="button" id="lengthFilter" data-bs-toggle="dropdown" aria-expanded="false">
				Show {{maxRows}}
					<span class="material-icons">expand_more</span>
				</button>

				<ul class="dropdown-menu" aria-labelledby="lengthFilter" 
				style="width: 110px !important;">
					<li><a class="dropdown-item" (click)="adjustNumberOfItems(5)">Show 5</a></li>
				    <li><a class="dropdown-item" (click)="adjustNumberOfItems(10)">Show 10</a></li>
				    <li><a class="dropdown-item" (click)="adjustNumberOfItems(25)">Show 25</a></li>
				    <li><a class="dropdown-item" (click)="adjustNumberOfItems(50)">Show 50</a></li>
				</ul>
			</span>
		</div>
	</div>

	<!-- EMPTY TABLE -->
	<div class="img-info justify-content-start ps-0 pt-5 d-flex bg-gray container" 
	[@animate]="{value:'*', params:{ x:'-50px', delay:'300ms' }}"
	*ngIf="listDataSource?.length === 0">
		<div class="flex-wrap" style="margin-left: 20px;" >
			<h4>List of {{componentTitle}} is currently empty. </h4>
		</div>
	</div>

	<ng-container *ngIf="listDataSource?.length === 0">
		<div class="d-none d-sm-block"><br><br><br></div>
		<!-- <hr> -->
	</ng-container>

	<!-- TABLE DATA DESKTOP  [hidden]="!withHeader"-->
	<div id="table-container" class="d-none d-md-inline w-100" 
	*ngIf="listDataSource?.length > 0">
		<table class="mt-0 pt-0 w-100" mat-table matSort 
		[dataSource]="dataSource" *ngIf="!loading">
			<ng-container *ngFor="let header of displayedColumns; index as cindex" 
			[matColumnDef]="header?.col_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header 

				[@animate]="{value:'*', params:{ x:'-10px', delay:'100ms' }}"
				[ngStyle]="{
					'width': header?.col_name == 'profile_image' || header?.col_name == 'photourl' || header?.col_name == 'receipt_photo' 
					|| header?.col_name == 'icon' 
					|| header?.col_name == 'delete' ? '50px'
					: header?.col_name == 'action' ? '144px' 
					: ''
				}"
				>
					<span *ngIf="header?.col_name !== 'profile_image' && header?.col_name !== 'photourl' && header?.col_name !== 'receipt_photo' 
					&& header?.col_name !== 'delete'" 
					class="table_img float-end" 
					style="position: absolute; right: 0;" 
					[ngStyle]="{
						'position': header?.type ? 'absolute' : '',  
						'right': header?.type === 'currency' ? '0' : ''
					}"
					[innerHtml]="header?.title"></span>
				</th>

				<td mat-cell *matCellDef="let data"
				[ngClass]="[header?.type === 'currency' ? 'text-end' : '', header?.type === 'number' ? 'ps-5' : '']"
				[@animate]="{value:'*', params:{ x:'-10px', delay:'100ms' }}"> 
					<span class="flex-wrap ms-2" 
					*ngIf="header?.col_name === 'full_name'
					&& header?.col_name !== 'first_name'
					&& header?.col_name !== 'last_name' 
					&& header?.col_name !== 'icon'
					&& header?.col_name !== 'action'
					&& header?.col_name !== 'profile_image' && header?.col_name !== 'photourl' && header?.col_name !== 'receipt_photo'">
						{{ data.first_name }} {{data.last_name}} 
					</span>

					<ng-container>
						<span *ngIf="header?.col_name !== 'profile_image' && header?.col_name !== 'photourl'
						&& header?.col_name !== 'receipt_photo'
						 && header?.col_name !== 'icon'
						 && header?.col_name !== 'action'
						 && header?.type !== 'date'
						 && header?.col_name !== 'status'
						 && header?.col_name !== 'stage_name'"

						 [ngClass]="header?.type === 'hash_code' ? 'text-primary' : ''">
						 	<!-- TRUE | FALSE VALUE CONVERT TO YES OR NO -->
							<span *ngIf="header?.type === 'currency'">$</span>
							{{ 
								(
									data[header?.col_name] === true ? 'Yes' 
									: data[header?.col_name] === false ? 'No'
									: data[header?.col_name]
								) || '-' 
							}} 
						</span>

						<span *ngIf="header?.type === 'date'">
							{{ data[header?.col_name] | date: 'dd-MM-yyyy' || '-'}}
						</span>

						<span *ngIf="header?.col_name === 'status' || header?.col_name === 'stage_name'">
							<button class="btn btn-status text-capitalize" (click)="checkPendingStatus(data[header?.col_name], data?.receipt_id)"
							[ngClass]="data[header?.col_name]?.split(' ').join('-').toLowerCase()">
							{{ data[header?.col_name] === 'Policy' ? 'Bought Policy' : data[header?.col_name] === 'Recruited' ? 'Licensed' : data[header?.col_name] }} </button>
						</span>

						<span *ngIf="header?.col_name == 'profile_image'" class="table_img">
							<img [src]="data?.profile_image" >
						</span>
						<span *ngIf="header?.col_name == 'photourl'" class="table_img">
							<img [src]="data?.photourl" >{{data?.photourl}}
						</span>
						<span *ngIf="header?.col_name == 'receipt_photo'" class="table_img">
							<!-- <img [src]="data?.receipt_photo" >{{data?.receipt_photo}} -->
							<a [href]="data?.receipt_photo" target="_blank">Receipt</a>
						</span>
						
					</ng-container>
				</td>

			</ng-container>

		  	<tr mat-header-row *matHeaderRowDef="selectedColumns;" class="text-center"></tr>
		  	<tr mat-row *matRowDef="let row; let i = index; columns: selectedColumns;"
		  	(click)="selectRows(row)"
		  	(dblclick)="viewDetailDialog(row)"
		  	[ngClass]="selectedRows.indexOf(row) > -1 ? 'bg-selected': ''"></tr>
		</table>
	</div>

	<!-- TABLE DATA MOBILE -->
	<div id="table-container-mobile" class="d-block d-md-none">
		<table class="table mt-0 pt-0" mat-table matSort 
		[dataSource]="dataSource" *ngIf="!loading">
			<ng-container 
			*ngFor="let header of displayedColumns; index as cindex" 
			[matColumnDef]="header?.col_name">
				<tr class="w-100" *matCellDef="let data"> 
					<td class="w-100">
						<span class="flex-wrap ms-2" 
						*ngIf="header?.col_name === 'full_name' 
						&& header?.col_name !== 'icon'
						&& header?.col_name !== 'first_name'
						&& header?.col_name !== 'last_name'
						&& header?.col_name !== 'action'
						&& header?.col_name !== 'profile_image' && header?.col_name !== 'photourl' && header?.col_name !== 'receipt_photo'">
							{{ data.first_name }} {{data.last_name}} 
						</span>

						<ng-container>
							<span *ngIf="header?.col_name !== 'profile_image' && header?.col_name !== 'photourl' && header?.col_name !== 'receipt_photo'
							 && header?.col_name !== 'icon'
							 && header?.col_name !== 'action'
							 && header?.type !== 'date'
							 && header?.col_name !== 'status'
							 && header?.col_name !== 'stage_name'"
							 [ngClass]="header?.type === 'hash_code' ? 'order-number' : ''">
							 	<!-- TRUE | FALSE VALUE CONVERT TO YES OR NO -->
								
								{{header?.title}}: 
								<ng-container *ngIf="header?.type === 'currency'">$</ng-container>
								{{ 
									(
										data[header?.col_name] === true ? 'Yes' 
										: data[header?.col_name] === false ? 'No'
										: data[header?.col_name]
									) || '-' 
								}} 
							</span>

							<span *ngIf="header?.type === 'date'">
								{{header?.title}}: {{ data[header?.col_name] | date: 'dd-MM-yyyy' || '-'}}
							</span>

							<span *ngIf="header?.col_name === 'status' || header?.col_name === 'stage_name'">
								<button class="btn btn-status" 
								[ngClass]="data[header?.col_name]?.split(' ').join('-').toLowerCase()">
								{{ data[header?.col_name] }} </button>
							</span>

							<span *ngIf="header?.col_name == 'profile_image'" class="table_img">
								<img [src]="data?.profile_image" >
							</span>
							<span *ngIf="header?.col_name == 'photourl'" class="table_img">
								<img [src]="data?.photourl" >{{data?.photourl}}
							</span>
							<span *ngIf="header?.col_name == 'receipt_photo'" class="table_img">
								<!-- <img [src]="data?.receipt_photo" >{{data?.receipt_photo}} -->
								<a [href]="data?.receipt_photo" target="_blank">Receipt</a>
							</span>
						</ng-container>
					</td>
				</tr>
			</ng-container>

		  	<!-- <tr mat-header-row *matHeaderRowDef="selectedColumns"></tr> -->
		  	<tr mat-row *matRowDef="let row; let i = index; columns: selectedColumnsMobile;"
		  	(click)="selectRows(row)"
		  	(dblclick)="viewDetailDialog(row)"
		  	[ngClass]="selectedRows.indexOf(row) > -1 ? 'bg-selected': ''"></tr>
		</table>
	</div>

	<!-- NAVIGATOR DETAILS -->
	<div class="flex-wrap px-2 pt-2" *ngIf="listDataSource?.length > 0" style="border-top: solid 1px #c9c9c9;">
		<p class="float-start mt-2">Showing 1 to {{maxRows}} of {{listDataSource?.length}} entries
			<span class="ms-5" *ngIf="multipleSelect">
				<strong style="color: #8C7FF9">{{selectedRows?.length}}</strong>
				Rows selected
			</span>
		</p>

		<!-- PAGINATOR -->
		<p class="float-end paginator" *ngIf="paginate.length > 1">
			<span class="text-black" 
			(click)="page !== 1 ? changePage(page-1): ''"> 
				<img src="/assets/images/placeholder/icons/caret-left.png" class="img-fluid" width="7px">
			</span>

			<span 
			*ngFor="let i of paginate.slice(
				this.pageRange(this.page, this.paginate.length)['start'], 
				this.pageRange(this.page, this.paginate.length)['end']
			)"
			(click)="changePage(i)">
				<span [ngClass]="i === page ? 'text-purple': 'text-black'">{{i}}</span>
			</span>
			
			<span class="text-black" 
			(click)="page !== paginate.length ? changePage(page+1): ''"> 
				<img src="/assets/images/placeholder/icons/caret-right.png" class="img-fluid" width="7px">
			</span>
		</p>
	</div>
</section>