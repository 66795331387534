import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';

// import enum action types
import { CourseActionTypes } from '../actions/course.action';
import { CourseService } from '../../services/api/course.service';

@Injectable()
export class CourseEffect {
  constructor(
    private courseService: CourseService,
    private actions$: Actions
  ) {}

  public getCourseList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(CourseActionTypes.GET_COURSE_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('courseeffect');
        return this.courseService.getCourseList(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: CourseActionTypes.GET_COURSE_LIST_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: CourseActionTypes.GET_COURSE_LIST_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getCourseDetail = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(CourseActionTypes.GET_COURSE_DETAIL),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('courseeffect');
        return this.courseService.getCourseDetail(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: CourseActionTypes.GET_COURSE_DETAIL_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: CourseActionTypes.GET_COURSE_DETAIL_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getLessonList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(CourseActionTypes.GET_LESSON_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('courseeffect');
        return this.courseService.getLessonList(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: CourseActionTypes.GET_LESSON_LIST_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: CourseActionTypes.GET_LESSON_LIST_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getQuizList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(CourseActionTypes.GET_QUIZ_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('courseeffect');
        return this.courseService.getQuizList(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: CourseActionTypes.GET_QUIZ_LIST_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: CourseActionTypes.GET_QUIZ_LIST_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );
}
