import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  private server = environment.basePath;
  constructor(private http: HttpClient) {}

  saveUserDetails(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/auth/signup`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  registerUserDetails(data:any): Observable<any> {
    return this.http.post<any>(`${this.server}/auth/registerguest`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  private handleError(error: any, caught: any): any {
    throw error;
  }
}
