<div class="container-fluid bg-dark py-5" >
	<div class="container mt-4">
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="input-group mt-4 pe-lg-5">
				  <input type="text" class="form-control" placeholder="Your email address" aria-label="Your email address" aria-describedby="arrow">
				  <span class="input-group-text pe-5" id="arrow">
				  	<img src="/assets/images/placeholder/arrow-right.png" style="width: 20px;" class="me-3">
				  </span>
				</div>

				<h5 class="title-sub my-4 px-3 ps-lg-2">ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.</h5>

				<div class="d-flex pe-lg-5 mt-3 py-lg-4 ps-lg-2 d-none d-lg-flex">
					<div class="me-4">
						<img src="/assets/images/placeholder/app-store.png" class="store-image img-fluid hvr-grow">
					</div>
					
					<div class="me-4">
						<img src="/assets/images/placeholder/google-play-2.png" class="store-image img-fluid hvr-grow">
					</div>
				</div>
			</div>

			<!-- NAVIGATOR -->
			<div class="col-6 col-md-2 mb-2">
				<h5 class="title-about my-3 mt-3 text-uppercase">COMPANY</h5>
				<p class="title-list">Our Mission</p>
				<p class="title-list">Media</p>
				<p class="title-list">Careers</p>
				<p class="title-list">Research</p>
			</div>
			
			<div class="col-6 col-md-2 mb-2 d-none d-md-block">
				<h5 class="title-about mb-3 text-uppercase">SUPPORT</h5>
				<p class="title-list">
					<a [href]="'/#/help-centre'">Help centre</a>
				</p>
				<p class="title-list">
					<a [href]="'/#/contact-us'">Contact us</a>
				</p>
				<p class="title-list">
					<a [href]="'/#/presskit'">Presskit</a>
				</p>
				<p class="title-list">
					<a [href]="'/#/donate'">Donate</a>
				</p>
			</div>

			<div class="col-6 col-md-2 mb-2 d-none d-md-block">
				<h5 class="title-about mb-3 text-uppercase" routerLink="/legal">LEGAL</h5>
				<p class="title-list" routerLink="/terms-and-condition">Terms & Conditions</p>
				<p class="title-list" routerLink="/connectivity-terms">Connectivity terms</p>
				<!-- <p class="title-list">Connectivity terms</p> -->
				<p class="title-list" routerLink="/privacy-policy">Privacy policy</p>
				<p class="title-list" routerLink="/medical-disclaimer">Medical disclaimer</p>
			</div>

			
		</div>
	</div>
</div>

<div class="container-fluid bg-dark-2 py-2 pt-4">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-6">
				<h5 class="title-copyright">
					Copyright © 2022 MoveUp. All rights reserved. 
				</h5>
			</div>
		</div>
	</div>
</div>