import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { 
  Actions, 
  ofType, 
  createEffect 
} from '@ngrx/effects';

import { AuthService } from '../../services/api/auth.service';
import { AuthActionTypes } from '../actions/auth.action';

@Injectable()
export class AuthEffect {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
  ) {}


  public getAuth = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(AuthActionTypes.GET_AUTH),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.authService.getAuth(data?.payload)
          .pipe(
            // return payload
            map((result: any) => {         
              localStorage.setItem('token', result.data.token);
              localStorage.setItem('user', JSON.stringify(result.data)); 
              return {
                type: AuthActionTypes.GET_AUTH_SUCCESS,
                payload: result.data
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: AuthActionTypes.GET_AUTH_FAIL,
                payload: error,
              }),
            ),
          );
      })
    ),
  )

  public changePassword = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.CHANGE_PASSWORD),
      switchMap((data: any) => {
        return this.authService.changePassword(data?.payload)
          .pipe(
            map((result: any) => {         
              return {
                type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
                payload: true
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: AuthActionTypes.CHANGE_PASSWORD_FAIL,
                payload: error,
              }),
            ),
          );
      })
    ),
  )

  public forgotPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.FORGOT_PASSWORD),
      switchMap((data: any) => {
        return this.authService.forgotPassword(data?.payload)
          .pipe(
            map((result: any) => {         
              return {
                type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
                payload: true
              };
            }),
            catchError((error: any) =>
              // error handler
              of({
                type: AuthActionTypes.FORGOT_PASSWORD_FAIL,
                payload: error,
              }),
            ),
          );
      })
    ),
  )

}
