import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';

// import enum action types
import { DashboardActionTypes } from '../actions/dashboard.action';
import { DashboardService } from '../../services/api/dashboard.service';

@Injectable()
export class DashboardEffect {
  constructor(
    private dashboardService: DashboardService,
    private actions$: Actions
  ) {}

  public getDashboard = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(DashboardActionTypes.GET_DASHBOARD),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('dashboardeffect');
        return this.dashboardService.getDashboard(data.payload).pipe(
          // return payload
          map((result: any) => {
            return {
              type: DashboardActionTypes.GET_DASHBOARD_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: DashboardActionTypes.GET_DASHBOARD_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getDashboardCourse = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(DashboardActionTypes.GET_DASHBOARD_COURSE),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('dashboardeffect');
        return this.dashboardService.getDashboardCourse(data.payload).pipe(
          // return payload
          map((result: any) => {
            return {
              type: DashboardActionTypes.GET_DASHBOARD_COURSE_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: DashboardActionTypes.GET_DASHBOARD_COURSE_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );
}
