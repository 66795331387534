import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { SignUpService } from '../../services/api/sign-up.service';
import { SignUpActionTypes } from '../actions/sign-up.action';

@Injectable()
export class SignUpEffect {
  constructor(
    private signUpService: SignUpService,
    private actions$: Actions
  ) {}

  public saveUserDetails = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(SignUpActionTypes.SAVE_USER_DETAILS),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.signUpService.saveUserDetails(data?.payload).pipe(
          // return payload
          map((result: any) => {
            localStorage.setItem('token', result.access_token);
            localStorage.setItem('user', JSON.stringify(result.user)); 
            return {
              type: SignUpActionTypes.SAVE_USER_DETAILS_SUCCESS,
              payload: true,
            };
          }),
          catchError((error: any) =>
            of({
              type: SignUpActionTypes.SAVE_USER_DETAILS_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public registerUserDetails = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(SignUpActionTypes.REGISTER_USER_DETAILS),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.signUpService.registerUserDetails(data?.payload).pipe(
        // return payload
        map((result: any) => {
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('user', JSON.stringify(result.user)); 
          return {
            type: SignUpActionTypes.REGISTER_USER_DETAILS_SUCCESS,
            payload: true,
          };
        }),
        catchError((error: any) =>
          of({
            type: SignUpActionTypes.REGISTER_USER_DETAILS_FAIL,
            payload: error,
          })
        )
      );
    })
  )
);
}
