import { 
	OnboardAction,
	OnboardActionTypes
	
} from '../actions/onboard.action';

// Create new interface for reducer
export interface OnboardState {
	onboard: any;
    onboardList: any;
	pending: any;
	error: any;
	success: any;
	contactList: any;
	profile: any;
	leadList: any;
	updateStageResult: any;
}

// Set initial state of the data
export const ONBOARD_INITIAL_STATE: OnboardState = {
	onboard: {},
    onboardList: [],
	pending: false,
	error: null,
	success: null,
	contactList: null,
	profile: null,
	leadList: null,
	updateStageResult: null
}

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const OnboardReducer = (
	state: OnboardState = ONBOARD_INITIAL_STATE,
	action: OnboardAction,
	): OnboardState => {
		switch (action.type){

			case OnboardActionTypes.GET_ONBOARD:
			return { ...state, pending: true };

			case OnboardActionTypes.GET_ONBOARD_SUCCESS:
			return { ...state, onboard: action.payload, pending: false }

			case OnboardActionTypes.GET_ONBOARD_FAIL:
			return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.UPDATE_STAGE:
			return { ...state, pending: true };

			case OnboardActionTypes.UPDATE_STAGE_SUCCESS:
			return { ...state, updateStageResult: action.payload, pending: false }

			case OnboardActionTypes.UPDATE_STAGE_FAIL:
			return { ...state, pending: false, error: action.payload }

            case OnboardActionTypes.GET_ONBOARD_LIST:
            return { ...state, pending: true };

            case OnboardActionTypes.GET_ONBOARD_LIST_SUCCESS:
            return { ...state, onboardList: action.payload, pending: false }

            case OnboardActionTypes.GET_ONBOARD_LIST_FAIL:
            return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.SAVE_ONBOARD:
			return { ...state, pending: true };

			case OnboardActionTypes.SAVE_ONBOARD_SUCCESS:
			return { ...state, contactList: action.payload, pending: false }

			case OnboardActionTypes.SAVE_ONBOARD_FAIL:
			return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.SAVE_ONBOARD_MULTIPLE:
			return { ...state, pending: true };

			case OnboardActionTypes.SAVE_ONBOARD_MULTIPLE_SUCCESS:
			return { ...state, contactList: action.payload, pending: false }

			case OnboardActionTypes.SAVE_ONBOARD_MULTIPLE_FAIL:
			return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.GET_ONBOARD_PROFILE:
			return { ...state, pending: true };

			case OnboardActionTypes.GET_ONBOARD_PROFILE_SUCCESS:
			return { ...state, profile: action.payload, pending: false }

			case OnboardActionTypes.GET_ONBOARD_PROFILE_FAIL:
			return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.GET_ONBOARD_DEAL_LIST:
			return { ...state, pending: true };

			case OnboardActionTypes.GET_ONBOARD_DEAL_LIST_SUCCESS:
			return { ...state, leadList: action.payload, pending: false }

			case OnboardActionTypes.GET_ONBOARD_DEAL_LIST_FAIL:
			return { ...state, pending: false, error: action.payload }

			case OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT:
			return { ...state, pending: true };

			case OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_SUCCESS:
			return { ...state, success: action.payload, pending: false }

			case OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_FAIL:
			return { ...state, pending: false, error: action.payload }

			default:
			return state;
		}
	}
	
