import { 
	AdminAction,
	AdminActionTypes
	
} from '../actions/admin.action';

// Create new interface for reducer
export interface AdminState {
    lifeguideList: any;
	pending: any;
	error: any;
	success: any;
	approveResult: any;
}

// Set initial state of the data
export const ADMIN_INITIAL_STATE: AdminState = {
    lifeguideList: [],
	pending: false,
	error: null,
	success: null,
	approveResult: null
}

/*
	Create Reducer
	Take 2 Parameter: from Event 
	@param 
		state: value (event, pending, error)
		action: from action type
*/
export const AdminReducer = (
	state: AdminState = ADMIN_INITIAL_STATE,
	action: AdminAction,
	): AdminState => {
		switch (action.type){

			case AdminActionTypes.GET_LIFEGUIDE_LIST:
			return { ...state, pending: true };

			case AdminActionTypes.GET_LIFEGUIDE_LIST_SUCCESS:
			return { ...state, lifeguideList: action.payload.data, pending: false }

			case AdminActionTypes.GET_LIFEGUIDE_LIST_FAIL:
			return { ...state, pending: false, error: action.payload }

			case AdminActionTypes.APPROVE_LIFEGUIDE_LIST:
			return { ...state, pending: true };

			case AdminActionTypes.APPROVE_LIFEGUIDE_LIST_SUCCESS:
			return { ...state, approveResult: action.payload, pending: false }

			case AdminActionTypes.APPROVE_LIFEGUIDE_LIST_FAIL:
			return { ...state, pending: false, error: action.payload }

			case AdminActionTypes.DECLINE_LIFEGUIDE_LIST:
			return { ...state, pending: true };

			case AdminActionTypes.DECLINE_LIFEGUIDE_LIST_SUCCESS:
			return { ...state, approveResult: action.payload, pending: false }

			case AdminActionTypes.DECLINE_LIFEGUIDE_LIST_FAIL:
			return { ...state, pending: false, error: action.payload }

			default:
			return state;
		}
	}
	
