import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';

// import enum action types
import { ChannelActionTypes } from '../actions/channel.action';
import { ChannelService } from '../../services/api/channel.service';

@Injectable()
export class ChannelEffect {
  constructor(
    private channelService: ChannelService,
    private actions$: Actions
  ) {}

  public getChannelListManager = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(ChannelActionTypes.GET_CHANNEL_LIST_MANAGER),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('channeleffect');
        return this.channelService.getChannelListManager(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: ChannelActionTypes.GET_CHANNEL_LIST_MANAGER_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: ChannelActionTypes.GET_CHANNEL_LIST_MANAGER_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getChannelListMember = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(ChannelActionTypes.GET_CHANNEL_LIST_MEMBER),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('channeleffect');
        return this.channelService.getChannelListMember(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: ChannelActionTypes.GET_CHANNEL_LIST_MEMBER_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: ChannelActionTypes.GET_CHANNEL_LIST_MEMBER_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getChannelDetail = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(ChannelActionTypes.GET_CHANNEL_DETAIL),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        console.log('channeleffect');
        return this.channelService.getChannelDetail(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: ChannelActionTypes.GET_CHANNEL_DETAIL_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: ChannelActionTypes.GET_CHANNEL_DETAIL_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );
}
