import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';

// import enum action types
import { OnboardActionTypes } from '../actions/onboard.action';
import { OnboardService } from '../../services/api/onboard.service';

@Injectable()
export class OnboardEffect {
  constructor(
    private onboardService: OnboardService,
    private actions$: Actions
  ) {}

  public getContactList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.GET_ONBOARD_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.getContactList(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.GET_ONBOARD_LIST_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.GET_ONBOARD_LIST_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public updateStage = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.UPDATE_STAGE),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.updateStage(data?.payload).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.UPDATE_STAGE_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.UPDATE_STAGE_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public saveOnboard = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.SAVE_ONBOARD),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.AddContact(data?.payload).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.SAVE_ONBOARD_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.SAVE_ONBOARD_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public saveOnboardMultiple = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.SAVE_ONBOARD_MULTIPLE),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.AddMultipleContact(data?.payload).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.SAVE_ONBOARD_MULTIPLE_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.SAVE_ONBOARD_MULTIPLE_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getContactProfile = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.GET_ONBOARD_PROFILE),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.getContactDetails(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.GET_ONBOARD_PROFILE_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.GET_ONBOARD_PROFILE_FAIL,
              payload: error,
            })
          )
        );
      })
    )
  );

  public getDealList = createEffect(() =>
    this.actions$.pipe(
      // set type
      ofType(OnboardActionTypes.GET_ONBOARD_DEAL_LIST),
      // switch to a new observable and cancel previous subscription
      switchMap((data: any) => {
        return this.onboardService.getDealList(data).pipe(
          // return payload
          map((result: any) => {
            return {
              type: OnboardActionTypes.GET_ONBOARD_DEAL_LIST_SUCCESS,
              payload: result,
            };
          }),
          catchError((error: any) =>
            // error handler
            of({
              type: OnboardActionTypes.GET_ONBOARD_DEAL_LIST_SUCCESS,
              payload: error,
            })
          )
        );
      })
    )
  );

  public uploadPayment = createEffect(() =>
  this.actions$.pipe(
    // set type
    ofType(OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT),
    // switch to a new observable and cancel previous subscription
    switchMap((data: any) => {
      return this.onboardService.uploadPayment(data?.payload).pipe(
        // return payload
        map((result: any) => {
          return {
            type: OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_SUCCESS,
            payload: result.status,
          };
        }),
        catchError((error: any) =>
          // error handler
          of({
            type: OnboardActionTypes.UPLOAD_ONBOARD_PAYMENT_FAIL,
            payload: error,
          })
        )
      );
    })
  )
);

}
